import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { removeAuth } from "../helpers/auth";
import { useSelector } from "react-redux";
import { callAPI } from "../utils/apiUtils";
import { apiUrls } from "../utils/apiUrls";
import { errorMessage, successMessage } from "../Alertmsg";
import {
  BellFill,
  ChatLeftTextFill,
  EnvelopeAtFill,
  EnvelopeFill,
} from "react-bootstrap-icons";

export default function BuyerHeader() {
  const location = useLocation();
  let navigate = useNavigate();
  //destructuring pathname from location
  const { pathname } = location;
  const [notification, setNotification] = useState({});
  //Javascript split method to get the name of the path in array
  const header = pathname.split("/");

  const logout = async () => {
    try {
      const response = await callAPI(apiUrls.logout, {}, "GET");
      if (response.data.status == true) {
        successMessage(response.data.message);
        navigate("/login");
        removeAuth();
      } else {
        errorMessage(response.data.message);
      }
    } catch (err) {
      errorMessage(err.message);
    }
  };

  const handleLogout = () => {
    logout();
  };
  const users = JSON.parse(localStorage.getItem("userData"));
  const user = useSelector((state) => state.UserProfile.profile);

  const Notification = async () => {
    try {
      let query = { type: "User" };
      const response = await callAPI(
        apiUrls.messagecountnotification,
        query,
        "GET"
      );
      if (response.data.status == true) {
        setNotification(response.data.data);
      } else {
        errorMessage(response.data.message);
      }
    } catch (err) {
      errorMessage(err.message);
    }
  };

  const token = localStorage.getItem('accessToken')

  useEffect(() => {
    if(token){
      Notification();
    }
  
  }, [token]);


  return (
    <React.Fragment>
      <header className={header[1] !== "" ? "  " : ""}>
        <nav className="navbar navbar-expand-xl navbar-custom">
          <div className="container-fluid top-container">
            <NavLink className="navbar-brand" to="/dashboard">
              <img src="/img/white_logo.png" className="img-fluid" alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img
                src="/img/white_logo.png"
                className="img-fluid hambuger_menu"
                alt=""
              />
            </button>

            <div
              className="collapse navbar-collapse mobile_nav"
              id="navbarSupportedContent"
            >
               {token &&
              <ul className="d-flex customNav">
                {
                  (['buyer', 'seller_buyer'].includes(user?.customer_type ? user?.customer_type : users?.customer_type))
                  &&
                  <li className="dropdown">
                    <Link
                      className="login_user_pic dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Business For Sale
                    </Link>
                    <div className="formatheader dropdown-menu">
                      <h3>Business For Sale</h3>
                      <div className="d-flex buyerHeader">
                        <ul>
                          <li>
                            <p>Available Businesses</p>
                          </li>
                          <li>
                            <Link to="/buyer/campaigns" className="dropdown-item">
                              Browse Business
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/buyer/campaigns/list/interested">
                              My Watch List
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/buyer/messages">
                              My Enquiry
                            </Link>
                          </li>
                        </ul>
                        {/* <ul>
                        <li>
                          <p>Services</p>
                        </li>
                        <li>
                          <Link to="/services/create" className="dropdown-item">
                            Browse Services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/services/list">
                            My Service Request
                          </Link>
                        </li>
                      </ul> */}
                        <ul>
                          <li>
                            <p>Contract</p>
                          </li>
                          <li>
                            <Link to="/buyer/campaigns" className="dropdown-item">
                              My Contract
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                }

                {
                  (['seller', 'seller_buyer'].includes(user?.customer_type ? user?.customer_type : users?.customer_type))
                  &&

                  <li className="dropdown">
                    <Link
                      className="login_user_pic dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Seller
                    </Link>
                    <div className="formatheader dropdown-menu">
                      <h3>Seller</h3>
                      <div className="d-flex buyerHeader">
                        <ul>
                          <li>
                            <p>Listing</p>
                          </li>
                          <li>
                            <Link
                              to="/seller/campaigns"
                              className="dropdown-item"
                            >
                              My Listing
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/buyer/messages">
                              My Enquiry
                            </Link>
                          </li>
                        </ul>
                        {/* <ul>
                        <li>
                          <p>Services</p>
                        </li>
                        <li>
                          <Link to="/services/create" className="dropdown-item">
                            Browse Services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/services/list">
                            My Service Request
                          </Link>
                        </li>
                      </ul> */}
                        <ul>
                          <li>
                            <p>Contract</p>
                          </li>
                          <li>
                            <Link to="/buyer/campaigns" className="dropdown-item">
                              My Contract
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                }

                <li className="dropdown">
                  <Link
                    className="login_user_pic dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                  </Link>
                  <div className="formatheader dropdown-menu">
                    <h3>Services</h3>
                    <div className="d-flex buyerHeader">
                      <ul>

                        <li>
                          <p>Kbroker’s Service</p>
                        </li>
                        <li>
                          <Link to="/services/create" className="dropdown-item">
                            Browse Services
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/services/list">
                            My Service Request
                          </Link>
                        </li>
                      </ul>

                    </div>
                  </div>
                </li>

                <li className="single">
                  <Link className="dropdown-item" to="/ticket">
                    Contact Us
                  </Link>
                </li>
              </ul>
}
              <ul className="d-flex"></ul>
              <ul></ul>

              <div
                class="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav ms-auto">
                  {token ?
                  <>
                  <li class="nav-item">
                    <Link class="nav-link active" to="/buyer/notification">
                      <BellFill />
                      {notification?.notification_count > 0 ? (
                        <sup style={{ fontSize: "13px", marginLeft: "3px" }}>
                          {notification?.notification_count}
                        </sup>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/buyer/messages">
                      <EnvelopeFill />
                      {notification?.message_count > 0 ? (
                        <sup style={{ fontSize: "13px", marginLeft: "3px" }}>
                          {notification?.message_count}
                        </sup>
                      ) : (
                        <></>
                      )}

                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/ticket">
                      <svg width="25" height="30" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1973_8573)">
                          <path d="M20 14H10V20H8V12H14V8H6V14H4C2.9 14 2 14.9 2 16V28C2 29.1 2.9 30 4 30H20C21.1 30 22 29.1 22 28V16C22 14.9 21.1 14 20 14Z" fill="white" />
                        </g>
                        <path d="M23.04 6.5V5.252H25.536V14H24.156V6.5H23.04Z" fill="white" />
                        <defs>
                          <clipPath id="clip0_1973_8573">
                            <rect width="24" height="24" fill="white" transform="translate(0 8)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </Link>
                  </li>
                  </>
                  :
                  <li class="nav-item">
                    <Link class="nav-link active" to="/login">
                      Login
                    </Link>
                  </li>
                    }
                </ul>
                {token &&
                <ul className="d-flex">
                  <li className="dropdown" style={{ marginLeft: "10px" }}>
                    <Link
                      className="login_user_pic dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          user?.photoURL
                            ? user?.photoURL
                            : users?.photoURL
                              ? users?.photoURL
                              : "/img/chat-pic-2.png"
                        }
                        className="img-fluid"
                        alt=""
                      />
                      <h6 className="d-inline ms-1 mb-0 fs-6">
                        {user.displayName
                          ? user.displayName
                          : users?.displayName}
                      </h6>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end user_top_dropdown move-to-right">
                      <li>
                        <Link className="dropdown-item" to="/dashboard">
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/buyer/profile">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/transaction-history">
                          Transaction History
                        </Link>
                      </li>

                      <li onClick={handleLogout}>
                        <Link className="dropdown-item" to="#">
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                }
              </div>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}
