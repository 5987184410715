import { Actiontypes } from "../Action/ActionType";
const intialState = {
    profile:{},
    contract:{},
    Channel:""
  };
  
  export const UserProfile = (state = intialState, { type, payload }) => {
    switch (type) {
      case Actiontypes.PROFILE:
        return  { ...state, profile: payload };
      default:
        return state;
    }
  };


  export const MakeChannel = (state = intialState, { type, payload }) => {
    switch (type) {
      case Actiontypes.MakeChannel:
        return  { ...state, Channel: payload };
      default:
        return state;
    }
  };

  

  export const UserContract = (state = intialState, { type, payload }) => {
    switch (type) {
      case Actiontypes.Contract:
        return  { ...state, contract: payload };
      default:
        return state;
    }
  };
  