import React  from "react";
import {  Link, NavLink } from "react-router-dom";



export default function NoAuthLayout(props) {

  return (
   <>
   <header>
      <nav className="navbar navbar-expand-xl navbar-custom">
        <div className="container-fluid top-container">
          <NavLink className="navbar-brand" to="#">
            <img src="/img/logo-icon.png" className="img-fluid" alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img
              src="/img/mobile-menu-icon.png"
              className="img-fluid hambuger_menu"
              alt=""
            />
          </button>
         
        </div>
      </nav>
      
    </header>
    {props.children}
    <React.Fragment>
        <footer className="footer">
            <section className="footer-outline py-lg-5 py-md-5 py-3">
                <div className="container custom_container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-5 mb-4">
                            <div className="luxurycarmarket">
                                <figure className="mb-4">
                                    <Link to="#"><img src="/img/Logowhite-logo.png" className="img-fluid" alt="" /></Link>
                                </figure>
                                <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                                    demonstrate the visual form.</p>
                                <div className="social_connect mb-0">
                                    <Link to="#"><i className="fa-brands fa-facebook-f"></i></Link>
                                    <Link to="#"><i className="fa-brands fa-instagram"></i></Link>
                                    <Link to="#"><i className="fa-brands fa-twitter"></i></Link>
                                    <Link to="#"><i className="fa-brands fa-linkedin"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-5 mb-5 ps-lg-5 ps-md-5 ps-3">
                            <div className="footer_links">
                                <h4>Links</h4>
                                <div className="d-flex">
                                    <ul>
                                        <li><Link to="#">Home</Link></li>
                                        <li><Link to="#">About us</Link></li>
                                        <li><Link to="#">Subscription</Link></li>
                                        <li><Link to="#">FAQ</Link></li>
                                        <li><Link to="#">Contact Us</Link></li>
                                    </ul>
                                    <ul className="ms-5">
                                   
                                        <li><Link to="#">Privacy Policy</Link></li>
                                        <li><Link to="#">Terms & Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="footer_links get_in_touch">
                                <h4>Get In Touch</h4>
                                <ul className="arrow-list mt-0">
                                    <li>
                                        <Link to="#">
                                            <i className="fa-sharp fa-solid fa-phone-volume"></i>
                                            <label> <span>Hotline 24/7 :</span>+91 9999 999 999</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fa-solid fa-envelope-open"></i>
                                            <label><span>Email Address :</span>dinaftale@hotmail.com</label>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <i className="fa-sharp fa-solid fa-location-dot"></i>
                                            <label><span>Address :</span>3910 Rubaiyat Road United States</label>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="rights">
                <div className="container" style={{borderTop: "1px solid grey" }}>
                    <div className="align-items-center">
                        <p>© {new Date().getFullYear()} dinaftale, All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
        </React.Fragment>
   </>
  )
}
