import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Guest = ({ children }) => {
    const token = localStorage.getItem('accessToken')
  const navigate = useNavigate();
  const users = JSON.parse(localStorage.getItem("userData"));
 useEffect(() => {
  if (token) {
    if(users.customer_type =="buyer" && users.customer_type =="seller_buyer"){
      navigate("/dashboard");
    }
    else{
      navigate("/dashboard");
    }
 }
}, [token]);

  return <>
  {children}</>;
};
export default Guest;