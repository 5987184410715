export const defaultConfig = {
//  baseAPIUrl:"http://localhost/ksng-backend/api/",
  baseAPIUrl: "https://ksng.superfastech.com/ksng-backend/api/",
  // baseAPIUrl:"https://app.kbrokers.sg/ksng-backend/api/"
};

export const pusherConfig = {
  key: "dc6fc423eae4edbfc5ae",
  cluster: "ap2",
};

export const UserType = {
  admin: "Admin",
  user: "User",
  support_user: "SupportUser",
};
