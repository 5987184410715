import React from 'react'
import { Link } from 'react-router-dom';
export default function BuyerFooter() {
    return (
        <React.Fragment>
            <footer className="footer">
                <section className="footer-outline py-lg-5 py-md-5 py-3">
                    <div className="container-fluid top-container width1300">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-5 mb-4">
                                <div className="luxurycarmarket">
                                    <figure className="mb-4">
                                        <Link to="/"><img src="/img/footer_logo.png" className="img-fluid" alt="" /></Link>
                                    </figure>

                                    <p className="text_white">Established since 1980, K.S. NG & Co has been providing auditing services to Singapore companies for decades., accumulated years of experience to help you in navigating the local rules and regulations.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-5 mb-5 ps-lg-5 ps-md-5 ps-3">
                                <div className="footer_links">
                                    <h4>Site Links</h4>
                                    <div className="d-flex">
                                        <ul>
                                            <li><Link to="/" className="text_white">About Us</Link></li>
                                            <li><Link to="/faq" className="text_white">FAQ</Link></li>
                                            <li><Link to="/" className="text_white">Privacy Policy</Link></li>
                                            <li><Link to="/" className="text_white">Disclamiers</Link></li>
                                            <li><Link to="/" className="text_white">Terms & Conditions</Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="footer_links get_in_touch">
                                    <h4>Find Us</h4>
                                    <ul className="arrow-list mt-0">
                                        <li className="mt-2 mb-2">
                                            <Link to="#">

                                                <label>22 Lorong 21A Geylang
                                                    #08-02
                                                    Prosper Industrial Buildings
                                                    Singapore 388431</label>
                                            </Link>
                                        </li>
                                        <li className="mt-2 mb-2">
                                            <Link to="#">

                                                <label>Tel: +62 67436577</label>
                                            </Link>
                                        </li>
                                        <li className="mt-2 mb-2">
                                            <Link to="#">

                                                <label>Email: enquiries@ksng.com.sg
                                                </label>
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="rights">
                    <div className="container-fluid top-container width1300" style={{ borderTop: "1px solid grey" }}>
                        <div className="align-items-center">
                            <p> All Rights Reserved - KBrokers</p>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
