export const apiUrls = {
  //maven api
  //      login:'login',
  //      register:'register',
  //      resendlink:'resend-link',
  //      profile:'profile',
  //      updateProfile:'profile',
  //      createusernda:'create-user-nda',
  //      existEmail:'exist-email',
  //      logout:'logout',
  //      onboarding:'onboarding',
  //      forgetPassword:'forget/password',
  //      updateprofileimage:'upload-profile',
  //      sellercampaignlist:'seller/campaign-list',
  //      investorcampaignlist:'investor/campaign-list',
  //      campaignview:'investor/campaign-view',
  //      chatUserList:'chat/all-users',
  //      saveChatMsg:'chat/messages',
  //      getMessage:'chat/getMessage',
  //      createGroup:'chat/create-group',
  //      groupList:'chat/list-group',
  //      groupsavechat:'chat/store-messages',
  //      groupchatmsg:'chat/get-messages',
  //      requestForBecomeBuyerSeller:'became_buyer_seller',
  //      campaignstore:'seller/campaign-store',
  //      commonimageadd:'common-image-add',
  //      campaigndetail:'seller/campaign-detail',
  //      campaignedit:'seller/campaign-edit',
  //      campaigndelete:'seller/campaign-delete'
  login: "login",
  register: "register",
  resendlink:'resend-link',
  profile: "profile",
  updateProfile: "profile",
  createusernda: "create-user-nda",
  panta_doc:'panta_doc',
  paymenttransaction:'payment-transaction',
  addwatchlist: "add-watch_list",
  existEmail: "exist-email",
  logout: "logout",
  onboarding: "onboarding",
  forgetPassword: "forget/password",
  updateprofileimage: "upload-profile",
  sellercampaignlist: "seller/campaign-list",
  investorcampaignlist: "investor/campaign-list",
  campaignview: "investor/campaign-view",
  campaignpreview:"campaign-preview",
  chatUserList: "chat/all-users",
  groupdetail:"chat/group/detail",
  saveChatMsg: "chat/messages",
  getMessage: "chat/getMessage",
  createGroup: "chat/create-group",
  groupList: "chat/list-group",
  groupsavechat: "chat/store-messages",
  groupchatmsg: "chat/get-messages",
  subscriptionlist: "subscription/list",
  subscriptionpaymentcall: "subscription/payment-call",
  paymentcall:'payment-call',
  subscriptionadd: "subscription/add",
  campaignstore: "seller/campaign-store",
  campaignlive:'seller/campaign-live',
  commonimageadd: "common-image-add",
  campaigndetail: "seller/campaign-detail",
  campaignedit: "seller/update",
  campaigndelete: "seller/campaign-delete",
  transactionhistory:"transaction-history/index",
  dashboard_list:"dashboard_list",
  contacttoksng:"contact-to-ksng",
  became_buyer_seller:"became_buyer_seller",
  ticketstore:"ticket/store",
  ticketindex:'ticket/index',
  ticketdetail:'ticket/detail',
  ticketrepliesstore:'ticket-replies/store',
  messagecountnotification:'message-count-notification',
  allnotification:'all-notification',
  servicerequestsstore:'service-requests/store',
  servicerequestsindex:'service-requests/index',
  servicerequestsdetai:'service-requests/detail',
  servicerequestsrepliesstore:'service-requests-replies/store'
};
