import React from 'react'

export default function BaseLayout(props) {
  return (
    <>
  
   {props.children}
  
   </>
  )
}
