import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const successMessage = (message) => {
    toastr.options = {
        positionClass: 'toast-top-full-width',
        hideDuration: 200,
        timeOut: 5000,
    };
    toastr.clear();
    setTimeout(() => toastr.success(message), 1000);
}
const errorMessage = (message) => {

    toastr.options = {
        positionClass: 'toast-top-full-width',
        hideDuration: 200,
        timeOut: 5000,
    };
    toastr.clear();
    setTimeout(() => toastr.error(message), 1000);
}
const warningMessage = (message) => {

    toastr.options = {
        positionClass: 'toast-top-full-width',
        hideDuration: 200,
        timeOut: 5000,
    };
    toastr.clear();
    setTimeout(() => toastr.warning(message), 1000);
}
export {errorMessage,successMessage,warningMessage}

