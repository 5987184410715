import React from 'react'
import BuyerHeader from '../Component/BuyerHeader'
import BuyerFooter from '../Component/BuyerFooter'

export default function BuyerDashboardLayout(props) {
  return (
    <> 
    <BuyerHeader/>
    {props.children}
    <BuyerFooter/>
    </>
  )
}
