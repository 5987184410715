import React from "react";
import Card from "react-bootstrap/Card";
export default function LoginLayout(props) {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row d-flex" style={{ justifyContent: "center" }}>
          <div
            className="col-md-4"
            style={{ justifyContent: "center", margin: "4em 0em" }}
          >
            <div
              className="text-left"
              style={{
                justifyContent: "center",
                display: "flex",
                margin: " 4em 0em",
              }}
            >
              <img
                src="img/logo-icon.png"
                height="35"
                className="logo-icon"
                alt="logo icon"
              />

              <br />
            </div>

            <div className="box-shadow">
              <h2>
                Welcome Back!
              </h2>
              <p>Start to buy or sell now.</p>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
