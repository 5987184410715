import "./App.css";
import { Routers } from "./router";
import { setPusherClient } from "react-pusher";
import Pusher from "pusher-js";
import { pusherConfig } from "./config";
import { useEffect } from "react";

function App() {


  // Pusher.logToConsole = true;

  // var pusher = new Pusher(pusherConfig.key, {
  //   cluster: pusherConfig.cluster,
  // });

  // var channel = pusher.subscribe('lll');
  
  // channel.bind('kk', function (data) {
  //   alert(JSON.stringify(data));
  // });

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;
