import React from "react";

export default function LoginRegisterLayoutBuyer(props) {
  return (
    <React.Fragment>
      <div class="container">
        <div class="row  mt-5">
          <div class="col-md-4 d-flex" style={{alignItems:"center"}}>{props.children}</div>
          <div class="col-md-2"></div>
          <div class="col-md-6">
            <img
              src="/img/blank-image.png"
              alt="no image"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
