import React, { Suspense, Fragment, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginRegisterLayoutBuyer from "./Layout/LoginRegisterLayoutBuyer";
import BaseLayout from "./Layout/BaseLayout";
import LoginLayout from "./Layout/LoginLayout";
import BuyerDashboardLayout from "./Layout/BuyerDashboardLayout";
import Authenticated from "./Guard/Authenticated";
import Guest from "./Guard/Guest";
import NoAuthLayout from "./Layout/NoAuthLayout";


export function Routers() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>....</div>}>
          <Routes>
            {routes.map((route, i) => {
              const Guard = route?.guard || Fragment;
              const Onbord = route?.onbord || Fragment;
              const Auth = route?.auth || Fragment;
              const Layout = route?.layout || Fragment;
              const Component = route?.element;
              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <Guard>
                      <Onbord>
                        <Auth>
                          <Layout>
                            <Component />
                          </Layout>
                        </Auth>
                      </Onbord>
                    </Guard>
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}


const routes = [
  {
    layout: BuyerDashboardLayout,
    exact: true,
    path: "/",
    element: lazy(() => import("./Buyer/Campaigns/AllCampaigns")),
  },

  {
    guard: Guest,
     layout: LoginLayout,
    exact: true,
    path: "/login",
    element: lazy(() => import("./Auth/Login")),
  },

  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: "/forget-password",
    element: lazy(() => import("./Auth/Forget_password")),
  },
  {
    guard: Guest,
    layout: LoginRegisterLayoutBuyer,
    exact: true,
    path: "/buyer/register",
    element: lazy(() => import("./Buyer/BuyerRegister")),
  },

  {
    guard: Guest,
    exact: true,
    path: "/buyer/register/2",
    element: lazy(() => import("./Auth/RegisterStep2")),
  },
  {
    guard: Guest,
    exact: true,
    path: "/buyer/register/3",
    element: lazy(() => import("./Auth/RegisterStep3")),
  },

  {
    guard: Authenticated,
    layout: BuyerDashboardLayout,
    exact: true,
    path: "/buyer/profile",
    element: lazy(() => import("./Buyer/BuyerProfile")),
  },

  {
    guard: Authenticated,
    layout: BuyerDashboardLayout,
    exact: true,
    path: "/faq",
    element: lazy(() => import("./CmsPages/Faq")),
  },

  
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/dashboard",
    element: lazy(() => import("./Buyer/Dashboard")),
  },
  {
    guard: Authenticated,
    exact: true,
    path: "/buyer/onboarding",
    element: lazy(() => import("./Buyer/Onboarding")),
  },
  {
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/campaigns",
    element: lazy(() => import("./Buyer/Campaigns/AllCampaigns")),
  },
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/campaigns/list/:id",
    element: lazy(() => import("./Buyer/Campaigns/AllCampaigns")),
  },
  {
   
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/campaigns/:id",
    element: lazy(() => import("./Buyer/Campaigns/CampaignsDetails")),
  },

  {
    exact: true,
    layout: NoAuthLayout,
    path: "/campaign-preview/:id",
    element: lazy(() => import("./Buyer/Campaigns/CampaignsPreview")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/nda/success",
    element: lazy(() => import("./Buyer/Campaigns/NdaSucess")),
  },
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/pendadoc/sign",
    element: lazy(() => import("./Buyer/Campaigns/PendadocSign")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/messages",
    element: lazy(() => import("./Chat/index")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/messages/:id",
    element: lazy(() => import("./Chat/index")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/seller/campaigns",
    element: lazy(() => import("./Seller/Campaigns/AllCampaigns")),
  },
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/seller/campaigns/create",
    element: lazy(() => import("./Seller/Campaigns/Create")),
  },
  
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/seller/campaigns/edit/:id",
    element: lazy(() => import("./Seller/Campaigns/Edit")),
  },
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/transaction-history",
    element: lazy(() => import("./PaymentHistory/Index")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/ticket",
    element: lazy(() => import("./Ticket/Index")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/ticket/view/:id",
    element: lazy(() => import("./Ticket/ViewTicket")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/services/create",
    element: lazy(() => import("./Services/Index")),
  },
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/services/list",
    element: lazy(() => import("./Services/ServiceList")),
  },
  
  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/services/reply/:id",
    element: lazy(() => import("./Services/ServiceReply")),
  },

  {
    guard: Authenticated,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/notification",
    element: lazy(() => import("./Notification/Index")),
  },
];
